var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'timeline-item',
		_vm.getColor(_vm.item.diet),
		{
			'align-right': (_vm.left > 40),
			'align-center': (_vm.left < 40 && _vm.right < 40),
			'is-left-open': (_vm.left < 0),
			'is-right-open': (100 - _vm.right > 100)
		}
	],on:{"click":function($event){return _vm.onOpenModal(Object.assign({}, _vm.item, {rel: _vm.items}))}}},[_c('div',{staticClass:"timeline-bar",style:({
			marginLeft: Math.max(_vm.left, 0) + '%',
			width: _vm.width
		}),attrs:{"title":((_vm.item.name) + ", " + (_vm.item.start) + " – " + (_vm.item.end) + "  Ma, " + (_vm.item.diet))}},[_c('div',{staticClass:"timeline-info"},[_c('figure',{staticClass:"timeline-icon"},[_c('BaseIcon',{attrs:{"src":'animals/' + (_vm.item.icon || _vm.item.slug + '.svg')}})],1),_c('span',{staticClass:"timeline-name"},[_vm._v(_vm._s(_vm.item.name))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }