<template>
	<div
		:class="[
			'timeline-item',
			getColor(item.diet),
			{
				'align-right': (left > 40),
				'align-center': (left < 40 && right < 40),
				'is-left-open': (left < 0),
				'is-right-open': (100 - right > 100)
			}
		]"
		@click="onOpenModal({...item, rel: items})"
	>
		<div
			class="timeline-bar"
			:title="`${item.name}, ${item.start} – ${item.end}  Ma, ${item.diet}`"
			:style="{
				marginLeft: Math.max(left, 0) + '%',
				width: width
			}"
		>
			<div class="timeline-info">
				<figure class="timeline-icon">
					<BaseIcon :src="'animals/' + (item.icon || item.slug + '.svg')" />
				</figure>

				<span class="timeline-name">{{ item.name }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import {ModalMixin} from '@/mixins';
import {getStartDimension, getEndDimension} from '@/utils/dimension';

export default {
	mixins: [ModalMixin],
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		items: {
			type: Array,
			default: () => []
		},
		start: {
			type: Number,
			default: 252.2
		},
		end: {
			type: Number,
			default: 66
		}
	},
	computed: {
		left() {
			return getStartDimension(this.item.start, this.start, this.end);
		},
		right() {
			return getEndDimension(this.item.end, this.start, this.end);
		},
		width() {
			const
				left = Math.max(this.left, 0),
				width = 100 - left - Math.min(this.right, 100)
			;

			return ((width + left <= 100) ? width : 100 - left) + '%';
		}
	},
	methods: {
		getColor(name) {
			if (name) {
				return 'is-' + this.$store.getters.getDiet(name).slug;
			}
		}
	}
};
</script>
